<template>
  <div class="rooms-modify">
    <v-container class="rooms-modify__container" fluid>
      <entity-modify-header class="mb-10" :title="title" />

      <form-builder v-if="!isLoading" :schema="$options.schema" :initial-data="initialData" @submit="submit">
        <template #footer="{ valid }">
          <v-row class="mt-4">
            <v-col>
              <v-btn color="secondary" block class="primary--text" :to="backRoute">{{ $t('button.back') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn type="submit" color="primary" block :disabled="!valid">{{ submitButtonText }}</v-btn>
            </v-col>
          </v-row>
        </template>
      </form-builder>
    </v-container>
  </div>
</template>

<script>
// Services
import realtyService from '@/services/realty';
import notificationService from '@/services/notification';
import { normalizeRoom } from '@/services/serializations';
import analyticsService from '@/services/analytics';

// Schema
import { schema } from '@/schemas/room.schema';

// Components
import EntityModifyHeader from '@/components/EntityModifyHeader.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Utils
import { createModelData } from '@/schemas/createModelData';

// Constants
import { PROJECTS, ROOMS_DETAILED } from '@/constants/routes';
import { CREATE_ROOM, EDIT_ROOM } from '@/constants/analyticsActions';

export default {
  name: 'RoomsEdit',

  components: { EntityModifyHeader, FormBuilder },

  props: {
    isEdit: { type: Boolean, default: false },
  },

  data() {
    return {
      isLoading: false,
      initialData: createModelData(schema),
    };
  },

  computed: {
    submitButtonText() {
      return this.$t(this.isEdit ? 'button.edit_room' : 'button.add_room');
    },

    backRoute() {
      return this.isEdit
        ? { name: ROOMS_DETAILED, query: { id: this.$route.params.id } }
        : { name: PROJECTS, query: { page: this.$route.query?.prevPage || 1 } };
    },

    title() {
      return this.isEdit ? this.$t('room.edit_room') : this.$t('room.create_room');
    },
  },

  mounted() {
    if (this.isEdit) {
      this.isLoading = true;
      realtyService
        .getRoomById(this.$route.params.id)
        .then(room => {
          this.initialData = normalizeRoom(room);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },

  methods: {
    submit(data) {
      const saveRoom = this.isEdit ? realtyService.updateRoom : realtyService.createRoom;

      if (this.$options.notificationItem) {
        notificationService.remove(this.$options.notificationItem);
      }

      this.isLoading = true;
      analyticsService.track(this.isEdit ? EDIT_ROOM : CREATE_ROOM);

      const clients = [];

      if (data.status === 'sold' || data.status === 'rented') {
        if (data?.owner?.id) {
          clients.push({
            client: data.owner.id,
            clientType: 'owner',
            salesContractDate: data.salesContractDate,
            rentalPeriodStartDate: null,
            rentalPeriodEndDate: null,
          });
        }

        if (data.status === 'rented') {
          const splitPeriod = data.rentalPeriodDate.split(' - ');

          clients.push({
            client: data.tenant.id,
            clientType: 'tenant',
            salesContractDate: null,
            rentalPeriodStartDate: splitPeriod[0],
            rentalPeriodEndDate: splitPeriod[1],
          });
        }
      }

      saveRoom({
        ...data,
        goalRentCost: data.goalRentCost || 0,
        clients,
        parentUnit: data.parentUnit.id,
      })
        .then(room => {
          const message = this.isEdit ? this.$t('room.room_edited') : this.$t('room.room_added');
          notificationService.success(message, 2000);

          this.$router.push({ name: ROOMS_DETAILED, params: { id: room?.id || this.$route.params.id } });
        })
        .catch(error => {
          this.initialData = data;

          const keys = Object.keys(error?.response?.data);

          if (keys.length) {
            for (let i = 0; i < keys.length; i += 1) {
              const key = keys[i];
              const errorText = this.getErrorText(error.response.data, key);
              this.$options.notificationItem = notificationService.error(errorText);
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getErrorText(data, key) {
      return Array.isArray(data[key]) ? data[key][0] : data[key];
    },
  },

  notificationItem: null,
  schema,
};
</script>

<style lang="scss">
.rooms-modify {
  &__container {
    max-width: 548px !important;
  }
}
</style>
